
import React from 'react';
import { Link } from 'gatsby'

import styles from './products.module.scss';

import Layout from '../../components/layout';
import CallToAction from '../../components/cta';
import WayWeWork from '../../components/www';

import { qa, diploma, rolled } from '../../images/icons';
import { teaching_team } from '../../images/team';

export default () => (
  <Layout>
    <div className={styles.productPage}>
      <div className={styles.topSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>Academic Mentorship</div>
          <div className={styles.description}>This program designed to help students navigate the rigors of high school, develop their passions, distinguish themselves within their school and local community, and become more self-aware, confident, and aspirational young adults.</div>
        </div>
      </div>
      <div className={styles.iconSection}>
        <div className={styles.card}>
          <img src={qa} />
          <div className={styles.meta}>Holistic Mentorship</div>
          {/*<div className={styles.description}>Every student that joins our SAT program receives a customized plan for their preparation that is based on data. Using this cutting-edge analytical approach, we can learn the precise concepts each student struggles with on the exam. This allows us to provide customized and dynamic guidance to each student about how they can better improve upon their weaknesses.</div>*/}
          <div className={styles.description}>Meet 1-on-1 with an expert consultant to receive tailored guidance on academic planning, activities involvement, and independent project consulting. We use a team-based approach, so each student in our program benefits from the expertise of each of our counselors.</div>
        </div>
        <div className={styles.card}>
          <img src={diploma} />
          <div className={styles.meta}>Amazing Opportunities</div>
          {/*<div className={styles.description}>Students who enroll in at least 60 of hours of our SAT preparation program can expect to gain up to 200 points on their score.  This improvement is based on our curriculum, which emphasizes familiarity with the exam; just like any sport, mastering the SAT is all about PRACTICE--so in each of our lesson formats, we administer regular mock exams and tailored review sessions.</div>*/}
          <div className={styles.description}>We connect our students with incredible experiences that will not only help them stand out in the college admissions process, but most importantly help them grow as young adults. With our support, students have interned at high growth startups, university research labs, and launched their own ventures.</div>
        </div>
        <div className={styles.card}>
          <img src={rolled} />
          <div className={styles.meta}>Real Expertise</div>
          {/*<div className={styles.description}>In addition to degrees from world class universities, all our instructors scored in the 99th percentile on both the SAT & ACT and are subject-matter experts in the specific subjects they each. Most importantly, they are teachers at heart and are heavily invested in the improvement of each individual student. Learn more about our amazing team here.</div>*/}
          <div className={styles.description}>Our Shanghai-based team and global network of mentors includes former admissions officers from some of the top colleges in the US. College admissions is always changing, and our team of real experts is best positioned to support with a family's long-term planning. <Link to='/team/main'>Meet our team here.</Link></div>
        </div>
      </div>
      <WayWeWork />
      <CallToAction text='Want to learn more about how our counseling programs can help you achieve your goals?' cta='Schedule a Free Consultation'/>
      <div className={styles.formatSection}>
        <div className={styles.header}>Key Program Features</div>
        <div className={styles.description}>Our academic mentorship program provides 1-on-1 guidance for making the most of your high school experience. It begins in the 9th grade and lasts for the next 3 years until the student finishes 11th grade and begins focusing on college applications. In-person meetings occur once every two weeks, with regular communication on WeChat as needed.</div>
        <div className={styles.description}>Here are some more key details about the program’s curriculum:</div>
          <div className={styles.row}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>Personal Profile Development</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Customized college prep plan and identification of short and long-term goals</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Individualized ‘reading and writing enrichment’ curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Introductions to professionals in possible fields of interest</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>On-demand advice on academic projects (school papers, presentations, etc.)</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>Extracurricular Development</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Intensive consulting on independent projects outside of school</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Targeted feedback on how to stand out in school activities and clubs</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Comprehensive support with summer program applications</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Internship placement</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>Additional Services</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Receive comprehensive interview preparation with structured feedback</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Ensure key deadlines are met with effective application time management</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Learn how to effectively show “demonstrated interest” in your top schools</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Get advice on how to transition to life in college and secure internships</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)